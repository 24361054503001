import { AxiosInstance, AxiosError, InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { useAuthStore } from "@/stores/authStore";
import { useSession } from "@/composables/useSession";
import { dashboardRedirect } from "@/util/dashboardRedirect";
import { verifySvcApiGetError } from "@/util/api-error-code";
import { FormFileValidationFailure, EditFormIsLocked } from "@/constants/api-error-codes";
import { GeneralServerError } from "@/constants/errors";
import { captureRequestError, captureResponseError } from "./sentry";
import { ErrorHandlerResult, ErrorResponse, ExtendedAxiosRequestConfig } from "./types";

export function setupInterceptors(axios: AxiosInstance, errorToast: (message: string) => void) {
    const authStore = useAuthStore();
    const { refreshToken } = useSession();

    setupRequestInterceptor(axios, authStore);
    setupResponseInterceptor(axios, authStore, refreshToken, errorToast);
}

const setupResponseInterceptor = (
    axios: AxiosInstance,
    authStore: ReturnType<typeof useAuthStore>,
    refreshTokenFn: () => Promise<void>,
    errorToast: (message: string) => void,
) => {
    const responseHandler = (response: AxiosResponse) => response;

    const responseErrorHandler = async (error: AxiosError<ErrorResponse>) => {
        if (!error.response) {
            captureResponseError(error, { type: "network" });
            const message = error.message || "Network error occurred";
            errorToast(`Oops! ${message}`);
            return Promise.reject(error);
        }

        let result: ErrorHandlerResult;
        switch (error.response.status) {
            case 400:
                result = handle400Error(error);
                break;
            case 401:
                result = await handleAuthRefresh(error, axios, authStore, refreshTokenFn);
                if (result.retryRequest) {
                    return result.retryRequest();
                }
                break;
            case 404:
                captureResponseError(error, { type: "not_found" });
                result = { errorMessage: "", shouldReject: true };
                break;
            case 500:
                result = handle500Error(error);
                break;
            default:
                result = handleDefaultError(error);
        }

        if (result.errorMessage) {
            errorToast(result.errorMessage);
        }

        if (result.shouldReject) {
            return Promise.reject(error);
        }
    };

    return axios.interceptors.response.use(responseHandler, responseErrorHandler);
};

// Request interceptor handlers
const setupRequestInterceptor = (axios: AxiosInstance, authStore: ReturnType<typeof useAuthStore>) => {
    const requestHandler = (config: InternalAxiosRequestConfig) => {
        if (authStore.hasAuthToken) {
            config.headers.Authorization = `Bearer ${authStore.authToken}`;
        }
        return config;
    };

    const requestErrorHandler = (error: AxiosError) => {
        if (!error.request && !error.response) {
            captureRequestError(error);
        }
        return Promise.reject(error);
    };

    return axios.interceptors.request.use(requestHandler, requestErrorHandler);
};

// Response error handlers by status code
const handle400Error = (error: AxiosError<ErrorResponse>): ErrorHandlerResult => {
    const errorCode = error.response?.data?.code || "";
    captureResponseError(error, {
        type: "validation",
        code: errorCode,
    });

    if (errorCode === EditFormIsLocked) {
        dashboardRedirect();
        return { errorMessage: "", shouldReject: true };
    }

    let errorMessage = GeneralServerError;
    if (verifySvcApiGetError(errorCode)) {
        errorMessage = "";
    }

    if (errorCode === FormFileValidationFailure && error.response?.data?.error?.length) {
        errorMessage = error.response.data.error[0];
    }

    return { errorMessage, shouldReject: true };
};

const handleAuthRefresh = async (
    error: AxiosError,
    axios: AxiosInstance,
    authStore: ReturnType<typeof useAuthStore>,
    refreshTokenFn: () => Promise<void>,
): Promise<ErrorHandlerResult> => {
    const originalRequest = error.config as ExtendedAxiosRequestConfig;
    if (!originalRequest) return { errorMessage: "", shouldReject: true };

    if (originalRequest._retry) {
        captureResponseError(error, {
            type: "auth",
            subtype: "refresh_failed",
            originalUrl: originalRequest.url || "",
        });
        dashboardRedirect();
        return { errorMessage: "", shouldReject: true };
    }

    originalRequest._retry = true;

    try {
        await refreshTokenFn();
        if (!authStore.hasAuthToken) {
            captureResponseError(error, {
                type: "auth",
                subtype: "refresh_success_no_token",
            });
            dashboardRedirect();
            return { errorMessage: "", shouldReject: true };
        }

        originalRequest.headers.Authorization = `Bearer ${authStore.authToken}`;
        return {
            errorMessage: "",
            shouldReject: false,
            retryRequest: () => axios.request(originalRequest),
        };
    } catch (refreshError) {
        captureResponseError(refreshError, {
            type: "auth",
            subtype: "refresh_error",
            originalUrl: originalRequest.url || "",
        });
        dashboardRedirect();
        return { errorMessage: "", shouldReject: true };
    }
};

const handle500Error = (error: AxiosError<ErrorResponse>): ErrorHandlerResult => {
    captureResponseError(error, {
        type: "server_error",
    });
    const message = error.response?.data?.error || "Internal server error";
    return { errorMessage: `Oops! ${message}`, shouldReject: true };
};

const handleDefaultError = (error: AxiosError): ErrorHandlerResult => {
    captureResponseError(error, {
        type: "unknown",
    });
    return { errorMessage: GeneralServerError, shouldReject: true };
};
