import {
    GetFormError,
    GetFormMetaError,
    GetCompanyApplicantError,
    GetCompanyApplicantDocumentError,
    GetFilerError,
    GetBeneficialOwnersError,
    GetBeneficialOwnerDocumentError,
} from "@/constants/api-error-codes";

export const verifySvcApiGetError = (val: string): boolean => {
    const allowedCodes = [
        GetFormError,
        GetFormMetaError,
        GetCompanyApplicantError,
        GetCompanyApplicantDocumentError,
        GetFilerError,
        GetBeneficialOwnersError,
        GetBeneficialOwnerDocumentError,
    ];

    return allowedCodes.includes(val);
};
