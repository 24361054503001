import type { AxiosError, AxiosResponse } from "axios";

export class ApiError extends Error {
    errors: string[];

    constructor(exception: any) {
        let collectedErrors = [];
        const ex = exception as AxiosError;

        if (ex.response && ex.response.status === 400)
            collectedErrors = ApiError.collectCustomErrorsFromResponse(ex.response);

        super(collectedErrors.length ? collectedErrors[0] : ex.message);

        this.errors = collectedErrors;

        if (Error.captureStackTrace) Error.captureStackTrace(this, ApiError);
        else
            this.toString = () => {
                return this.name + ": " + this.message;
            };
    }

    private static collectCustomErrorsFromResponse(response: AxiosResponse) {
        if (!response.data) return [];

        if (!response.data.error && !response.data.error_description) return [];

        const responseData = response.data;

        const error = responseData.error_description ? responseData.error_description : responseData.error;

        return Array.isArray(error) ? error : [error];
    }
}
