import { useStore } from "./formStore";
import { usePaymentStore } from "./paymentStore";
import { useUserStore } from "./userStore";

export const resetUserData = () => {
    useUserStore().reset();
    resetFormData();
};

export const resetFormData = () => {
    useStore().reset();
    usePaymentStore().reset();
};
