<template>
    <div class="container-overlay">
        <div class="overlay-content">
            <b-spinner class="overlay-spinner" variant="info" label="Loading..." />
            <div class="text">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts"></script>
