<template>
    <div>
        <app-header :authorized="isAuthorized" />

        <div class="container body-content">
            <RouterView />
        </div>

        <footer class="footer">
            <div class="container">
                <p class="text-muted">
                    &copy; {{ new Date().getFullYear() }} BOIR forms
                    <span v-if="version" class="app-version">v{{ version }}</span>
                </p>
            </div>
        </footer>

        <overlay v-if="isTokenLoading || isFormLoading">
            <div>
                <template v-if="isTokenLoading">
                    <div>Authorization in progress</div>
                </template>
                <template v-else>
                    <div>Loading Form data</div>
                </template>
                <div>Please wait...</div>
            </div>
        </overlay>
    </div>
</template>

<script setup lang="ts">
    import { computed } from "vue";
    import { useToast } from "@/composables/useToast";
    import { useAxios } from "@/plugins/axios";
    import appHeader from "@/components/Header/index.vue";
    import overlay from "@/components/Overlay/index.vue";
    import { useAuthStore } from "@/stores/authStore";
    import { useAppStore } from "@/stores/appStore";
    import { getSessionStorageFromQs } from "@/util/sessionStorage";
    import { setupInterceptors } from "@/plugins/axios/interceptors";
    import { useSession } from "@/composables/useSession";

    const { errorToast } = useToast();
    const axios = useAxios();
    const authStore = useAuthStore();
    const appStore = useAppStore();

    const version = import.meta.env.VITE_APP_VERSION;

    setupInterceptors(axios, errorToast);
    getSessionStorageFromQs();

    useSession();

    const isAuthorized = computed(() => {
        return authStore.hasAuthToken;
    });

    const isTokenLoading = computed(() => {
        return appStore.isTokenFetching;
    });

    const isFormLoading = computed(() => {
        return appStore.isFormFetching;
    });
</script>
