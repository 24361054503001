import { type AxiosStatic } from "axios";
import { inject } from "vue";

import { axiosInjectionKey } from "@/constants/injection-keys";

export function useAxios() {
    const axios = inject<AxiosStatic>(axiosInjectionKey)!;

    return axios;
}
