export const PaymentStatusType = {
    Undefined: "PAYMENT_STATUS_UNDEFINED",
    None: "PAYMENT_STATUS_NONE",
    Failed: "PAYMENT_STATUS_FAILED",
    Success: "PAYMENT_STATUS_SUCCESS",
    Refund: "PAYMENT_STATUS_REFUND",
    Abandoned: "PAYMENT_STATUS_ABANDONED",
} as const;

export type PaymentStatusType = (typeof PaymentStatusType)[keyof typeof PaymentStatusType];
