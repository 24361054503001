import { useToast as useVueToastNotification } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

export function useToast() {
    const toast = useVueToastNotification();

    const successToast = (message: string) => {
        return toast.success(message, { position: "bottom-right" });
    };

    const errorToast = (message: string) => {
        return toast.error(message, { position: "bottom-right" });
    };

    return {
        successToast,
        errorToast,
    };
}
