import { App } from "vue";
import { PostHogSymbol, initPostHog } from "@/composables/usePosthog";
import { storeTrackingCookies } from "@/util/cookies";

export default {
    install: (app: App) => {
        // First get cookies from query string to ensure ingest_id is available
        // Use ingest_id if that's provided.
        // Takes care of redtrack cookies as well.
        storeTrackingCookies();

        // Then initialize PostHog / binds to sessionID if available
        const posthogInstance = initPostHog();

        if (posthogInstance) {
            app.provide(PostHogSymbol, posthogInstance);
            app.config.globalProperties.$posthog = posthogInstance;
        }
    },
};
