import { defineStore } from "pinia";
import { ref } from "vue";
import type { CompanyDetailsViewModel } from "@/models/company-details";
import type { BusinessTypeViewModel } from "@/models/business-type";
import type { EntityViewModel } from "@/models/entity";
import type { CompanyApplicantViewModel } from "@/models/company-applicant";
import type { CompanyApplicantDocumentViewModel } from "@/models/company-applicant-document";
import type { BeneficialOwnerViewModel } from "@/models/beneficial-owner";
import type { BeneficialOwnerDocumentViewModel } from "@/models/beneficial-owner-document";
import type { FormViewModel } from "@/models/form";
import type { BeneficialOwner } from "@/models/svc/beneficial-owner";
import type { IdDocument } from "@/models/svc/id-document";
import { useAuthStore } from "@/stores/authStore";
import { useUserStore } from "@/stores/userStore";
import { IdTypes } from "@/constants/document-types";
import { StateCountries } from "@/constants/countries";
import { ApplicantTypes } from "@/constants/applicant-types";
import { IdentificationTypes } from "@/constants/identification-types";
import { Form } from "@/models/svc/form";
import formService from "@/services/form-service";
import { ApplicantDocumentImage } from "@/models/applicant-document-image";
import { AddressComponent } from "@/models/address-component";
import { InformationComponent } from "@/models/information-component";
import { formatDateToIsoString, inputDateFormat } from "@/util/helper";
import { PaymentStatusType } from "@/models/svc/payment-status-type";
import { useGtm } from "@gtm-support/vue-gtm";
import { BoirFormEvent, booleanToEvent } from "@/constants/tag-manager.ts";
import { PaymentDetailsViewModel } from "@/models/payment-details";

export const useStore = defineStore(
    "store",
    () => {
        const form = ref({
            step4: {},
            step6: {
                documents: [],
                images: [],
            },
        } as FormViewModel);

        const subStep = ref(null as number | null);
        const deviceType = ref("Unknown");
        const formId = ref("");
        const lastModifiedForm = ref(undefined as Form | undefined);
        const lastModifiedStep = ref(undefined as number | undefined);
        const failedBoIds = ref([] as string[]);
        const failedBoImageIds = ref([] as string[]);

        const setSubStep = (val: number | null) => {
            subStep.value = val;
        };

        const saveFormId = (val: string) => {
            formId.value = val;
        };

        const addFailedBoIds = (boId: string) => {
            failedBoIds.value.push(boId);
        };

        const removeFailedBoIds = (boId: string) => {
            failedBoIds.value = failedBoIds.value.filter((f) => f !== boId);
        };

        const addFailedBoImageIds = (boId: string) => {
            failedBoImageIds.value.push(boId);
        };

        const removeFailedBoImageIds = (boId: string) => {
            failedBoImageIds.value = failedBoImageIds.value.filter((f) => f !== boId);
        };

        const formIsPaid = (formVal?: Form) => {
            return !!formVal && formVal.paymentStatus === PaymentStatusType.Success;
        };

        const formIsRefunded = (formVal?: Form) => {
            return !!formVal && formVal.paymentStatus === PaymentStatusType.Refund;
        };

        const saveStep1 = (data: CompanyDetailsViewModel) => {
            form.value.step1 = data;
        };

        const getStep1 = async () => {
            return {
                formId: formId.value,
                deviceId: await useAuthStore().getDeviceId(),
                userId: useUserStore().userUid,
                step: 2,
                legalCompanyName: form.value.step1?.legalCompanyName,
                hasAlternateNames: form.value.step1?.hasAlternateNames,
                isForeignPooledInvestmentVehicle: form.value.step1?.isForeignPooledInvestmentVehicle,
                alternateCompanyNames: form.value.step1?.hasAlternateNames
                    ? form.value.step1?.alternateCompanyNames
                    : [],
                createdBeforeDate: form.value.step1?.createdBeforeDate,
            } as CompanyDetailsViewModel;
        };

        const fetchStep1 = () => {
            const companyDetails = {
                userId: lastModifiedForm.value?.userId || "",
                legalCompanyName: lastModifiedForm.value?.businessName || "",
                hasAlternateNames:
                    !!lastModifiedForm.value?.businessAltNames && lastModifiedForm.value?.businessAltNames.length > 0,
                isForeignPooledInvestmentVehicle: lastModifiedForm.value?.isForeignPooledInvestmentVehicle || false,
                alternateCompanyNames: lastModifiedForm.value?.businessAltNames || [],
                createdBeforeDate: lastModifiedForm.value?.isCreatedBfJan2024 || false,
            } as CompanyDetailsViewModel;

            saveStep1(companyDetails);
        };

        const saveStep2 = (data: BusinessTypeViewModel) => {
            form.value.step2 = data;
        };

        const getStep2 = async () => {
            return {
                formId: formId.value,
                deviceId: await useAuthStore().getDeviceId(),
                step: 3,
                usCompany: form.value.step2?.usCompany,
                stateFormed: form.value.step2?.usCompany ? form.value.step2?.stateFormed : null,
                countryFormed: form.value.step2?.usCompany ? null : form.value.step2?.countryFormed,
                registrationState: form.value.step2?.usCompany ? null : form.value.step2?.registrationState,
            } as BusinessTypeViewModel;
        };

        const fetchStep2 = () => {
            const businessType = {
                usCompany: lastModifiedForm.value?.isUsCreatedBased || false,
                stateFormed: lastModifiedForm.value?.stateFormed || "",
                countryFormed: lastModifiedForm.value?.countryFormed || "",
                registrationState: lastModifiedForm.value?.intlJurisdictionFormed || "",
            } as BusinessTypeViewModel;

            saveStep2(businessType);
        };

        const saveStep3 = (data: EntityViewModel) => {
            form.value.step3 = data;
        };

        const getStep3StreetAddress = () => {
            return form.value.step2?.usCompany || (!form.value.step2?.usCompany && subStep.value === 1)
                ? form.value.step3?.streetAddress
                : null;
        };

        const getStep3StreetAddress2 = () => {
            return form.value.step2?.usCompany || (!form.value.step2?.usCompany && subStep.value === 1)
                ? form.value.step3?.streetAddress2
                : null;
        };

        const getStep3City = () => {
            return form.value.step2?.usCompany || (!form.value.step2?.usCompany && subStep.value === 1)
                ? form.value.step3?.city
                : null;
        };

        const getStep3State = () => {
            return form.value.step2?.usCompany || (!form.value.step2?.usCompany && subStep.value === 1)
                ? form.value.step3?.state
                : null;
        };

        const getStep3Zip = () => {
            return form.value.step2?.usCompany || (!form.value.step2?.usCompany && subStep.value === 1)
                ? form.value.step3?.zip
                : null;
        };

        const getStep3AddressComponent = () => {
            return {
                address_1: getStep3StreetAddress(),
                address_2: getStep3StreetAddress2(),
                city: getStep3City(),
                state: getStep3State(),
                zip: getStep3Zip(),
            } as AddressComponent;
        };

        const getStep3AddressComponentReview = () => {
            return {
                address_1: form.value.step3?.streetAddress,
                address_2: form.value.step3?.streetAddress2,
                city: form.value.step3?.city,
                state: form.value.step3?.state,
                zip: form.value.step3?.zip,
            } as AddressComponent;
        };

        const getStep3 = async (subStepVal?: number) => {
            const hasSubStep = !!subStepVal && subStepVal === 1;

            return {
                formId: formId.value,
                deviceId: await useAuthStore().getDeviceId(),
                step: hasSubStep ? 3 : 4,
                subStep: hasSubStep ? 1 : undefined,
                domesticTaxIdType: form.value.step2?.usCompany ? form.value.step3?.domesticTaxIdType : null,
                foreignTaxIdType: form.value.step2?.usCompany ? null : form.value.step3?.foreignTaxIdType,
                taxCountry:
                    form.value.step2?.usCompany || form.value.step3?.foreignTaxIdType === IdTypes.ForeignEIN
                        ? StateCountries.US
                        : form.value.step3?.taxCountry,
                taxId: form.value.step3?.taxId,
                streetAddress: getStep3StreetAddress(),
                streetAddress2: getStep3StreetAddress2(),
                city: getStep3City(),
                state: getStep3State(),
                zip: getStep3Zip(),
            } as EntityViewModel;
        };

        const fetchStep3 = () => {
            const entity = {
                domesticTaxIdType: lastModifiedForm.value?.domesticTaxIdType || "",
                foreignTaxIdType: lastModifiedForm.value?.foreignTaxIdType || "",
                taxCountry: lastModifiedForm.value?.countryTaxIdIssued || "",
                taxId: lastModifiedForm.value?.taxId || "",
                streetAddress: lastModifiedForm.value?.bizAddress?.address1 || "",
                streetAddress2: lastModifiedForm.value?.bizAddress?.address2 || "",
                city: lastModifiedForm.value?.bizAddress?.city || "",
                state: lastModifiedForm.value?.bizAddress?.state || "",
                zip: lastModifiedForm.value?.bizAddress?.zip || "",
            } as EntityViewModel;

            saveStep3(entity);
        };

        const saveStep4Applicant = (data: CompanyApplicantViewModel) => {
            form.value.step4.applicant = data;
        };

        const getStep4Applicant = async (subStepVal?: number) => {
            const hasSubStep = !!subStepVal && subStepVal >= 1 && subStepVal <= 5;

            return {
                formId: formId.value,
                deviceId: await useAuthStore().getDeviceId(),
                step: hasSubStep ? 4 : 5,
                subStep: hasSubStep ? subStepVal : undefined,
                isApplicantBeneficialOwner:
                    form.value.step1?.isForeignPooledInvestmentVehicle || form.value.step1?.createdBeforeDate
                        ? form.value.step4?.applicant?.isApplicantBeneficialOwner
                        : form.value.step4?.applicant?.applicantType === ApplicantTypes.Both
                          ? true
                          : false,
                applicantType:
                    !form.value.step1?.isForeignPooledInvestmentVehicle && !form.value.step1?.createdBeforeDate
                        ? form.value.step4?.applicant?.applicantType
                        : form.value.step4?.applicant?.isApplicantBeneficialOwner
                          ? ApplicantTypes.BeneficialOnly
                          : ApplicantTypes.Neither,
                firstName: form.value.step4?.applicant?.firstName,
                middleName: form.value.step4?.applicant?.middleName,
                lastName: form.value.step4?.applicant?.lastName,
                birthDate:
                    subStep.value === null ? null : formatDateToIsoString(form.value.step4?.applicant?.birthDate),
                addressType: form.value.step4?.applicant?.addressType,
                streetAddress: form.value.step4?.applicant?.streetAddress,
                streetAddress2: form.value.step4?.applicant?.streetAddress2,
                city: form.value.step4?.applicant?.city,
                state: form.value.step4?.applicant?.state,
                zip: form.value.step4?.applicant?.zip,
                country: form.value.step4?.applicant?.country,
            } as CompanyApplicantViewModel;
        };

        const getStep4ApplicantAddressComponent = () => {
            return {
                address_1: form.value.step4?.applicant?.streetAddress,
                address_2: form.value.step4?.applicant?.streetAddress2,
                address_type: form.value.step4?.applicant?.addressType,
                city: form.value.step4?.applicant?.city,
                state: form.value.step4?.applicant?.state,
                zip: form.value.step4?.applicant?.zip,
                country: form.value.step4?.applicant?.country,
            } as AddressComponent;
        };

        const getStep4ApplicantInformationComponent = () => {
            return {
                firstName: form.value.step4?.applicant?.firstName,
                namePrefix: form.value.step4?.applicant?.middleName,
                lastName: form.value.step4?.applicant?.lastName,
                dob: inputDateFormat(form.value.step4?.applicant?.birthDate),
            } as InformationComponent;
        };

        const fetchStep4Applicant = async () => {
            try {
                const applicant = await formService.getCompanyApplicant(formId.value);
                const companyApplicant = {
                    isApplicantBeneficialOwner: applicant.isApplicantBeneficialOwner || false,
                    applicantType: applicant.applicantType || "",
                    firstName: applicant.personDetails?.firstName || "",
                    middleName: applicant.personDetails?.middleName || "",
                    lastName: applicant.personDetails?.lastName || "",
                    birthDate: applicant.personDetails?.dob || "",
                    addressType: applicant.personDetails?.address?.addressType || "",
                    streetAddress: applicant.personDetails?.address?.address1 || "",
                    streetAddress2: applicant.personDetails?.address?.address2 || "",
                    city: applicant.personDetails?.address?.city || "",
                    state: applicant.personDetails?.address?.state || "",
                    zip: applicant.personDetails?.address?.zip || "",
                    country: applicant.personDetails?.address?.country || "",
                } as CompanyApplicantViewModel;

                saveStep4Applicant(companyApplicant);
            } catch (error) {
                // used to be empty
            }
        };

        const saveStep4Document = (data: CompanyApplicantDocumentViewModel) => {
            form.value.step4.document = data;
        };

        const getStep4Document = async () => {
            return {
                formId: formId.value,
                deviceId: await useAuthStore().getDeviceId(),
                step: 4,
                subStep: 3,
                applicantDocumentType: form.value.step4?.document?.applicantDocumentType,
                identificationNumber: form.value.step4?.document?.identificationNumber,
                stateOfIssue:
                    form.value.step4?.document?.applicantDocumentType === IdentificationTypes.UsDriverLicense ||
                    form.value.step4?.document?.applicantDocumentType === IdentificationTypes.StateId
                        ? form.value.step4?.document?.stateOfIssue
                        : null,
                expirationDate: formatDateToIsoString(form.value.step4?.document?.expirationDate),
                countryOfIssue:
                    form.value.step4?.document?.applicantDocumentType === IdentificationTypes.ForeignPassport
                        ? form.value.step4?.document?.countryOfIssue
                        : StateCountries.US,
            } as CompanyApplicantDocumentViewModel;
        };

        const saveStep4Image = (data: ApplicantDocumentImage) => {
            form.value.step4.image = data;
        };

        const getStep4Image = async () => {
            return {
                formId: formId.value,
                deviceId: await useAuthStore().getDeviceId(),
                step: 5,
                userId: useUserStore().userUid,
                document: form.value.step4?.image?.document,
                filename: form.value.step4?.image?.document?.name,
            } as ApplicantDocumentImage;
        };

        const fetchStep4DocumentAndFile = async () => {
            try {
                const document = await formService.getCompanyApplicantDocument(formId.value);
                const applicantDocument = {
                    applicantDocumentType: document.type || "",
                    identificationNumber: document.number || "",
                    stateOfIssue: document.stateIssued || "",
                    expirationDate: document.expirationDate || "",
                    countryOfIssue: document.countryIssued || "",
                } as CompanyApplicantDocumentViewModel;

                const applicantFile = {
                    document: null,
                    filename: document.filename,
                } as ApplicantDocumentImage;

                saveStep4Document(applicantDocument);
                saveStep4Image(applicantFile);
            } catch (error) {
                // used to be empty
            }
        };

        const saveStep4Filer = (data: CompanyApplicantViewModel) => {
            form.value.step4.filer = data;
        };

        const getStep4Filer = async () => {
            return {
                formId: formId.value,
                deviceId: await useAuthStore().getDeviceId(),
                step: 4,
                subStep: 5,
                firstName: form.value.step4?.filer?.firstName,
                middleName: form.value.step4?.filer?.middleName,
                lastName: form.value.step4?.filer?.lastName,
                birthDate: formatDateToIsoString(form.value.step4?.filer?.birthDate),
                addressType: form.value.step4?.filer?.addressType,
                streetAddress: form.value.step4?.filer?.streetAddress,
                streetAddress2: form.value.step4?.filer?.streetAddress2,
                city: form.value.step4?.filer?.city,
                state: form.value.step4?.filer?.state,
                zip: form.value.step4?.filer?.zip,
                country: form.value.step4?.filer?.country,
            } as CompanyApplicantViewModel;
        };

        const getStep4FilerAddressComponent = () => {
            return {
                address_1: form.value.step4?.filer?.streetAddress,
                address_2: form.value.step4?.filer?.streetAddress2,
                address_type: form.value.step4?.filer?.addressType,
                city: form.value.step4?.filer?.city,
                state: form.value.step4?.filer?.state,
                zip: form.value.step4?.filer?.zip,
                country: form.value.step4?.filer?.country,
            } as AddressComponent;
        };

        const getStep4FilerInformationComponent = () => {
            return {
                firstName: form.value.step4?.filer?.firstName,
                namePrefix: form.value.step4?.filer?.middleName,
                lastName: form.value.step4?.filer?.lastName,
                dob: inputDateFormat(form.value.step4?.filer?.birthDate),
            } as InformationComponent;
        };

        const fetchStep4Filer = async () => {
            try {
                const filer = await formService.getFiler(formId.value);
                const applicantFiler = {
                    firstName: filer.personDetails?.firstName || "",
                    middleName: filer.personDetails?.middleName || "",
                    lastName: filer.personDetails?.lastName || "",
                    birthDate: filer.personDetails?.dob || "",
                    addressType: filer.personDetails?.address?.addressType || "",
                    streetAddress: filer.personDetails?.address?.address1 || "",
                    streetAddress2: filer.personDetails?.address?.address2 || "",
                    city: filer.personDetails?.address?.city || "",
                    state: filer.personDetails?.address?.state || "",
                    zip: filer.personDetails?.address?.zip || "",
                    country: filer.personDetails?.address?.country || "",
                } as CompanyApplicantViewModel;

                saveStep4Filer(applicantFiler);
            } catch (error) {
                // used to be empty
            }
        };

        const saveStep5 = (data: BeneficialOwnerViewModel[]) => {
            form.value.step5 = data;
        };

        const getStep5 = async (data: BeneficialOwnerViewModel[] | BeneficialOwner[]) => {
            return (await Promise.all(
                data.map(async (step5) => ({
                    formId: formId.value,
                    deviceId: await useAuthStore().getDeviceId(),
                    step: 6,
                    isMinor: (step5 as BeneficialOwnerViewModel)?.isMinor ?? (step5 as BeneficialOwner).isMinorOrChild,
                    firstName:
                        (step5 as BeneficialOwnerViewModel)?.firstName ??
                        (step5 as BeneficialOwner).personDetails?.firstName,
                    middleName:
                        (step5 as BeneficialOwnerViewModel)?.middleName ??
                        (step5 as BeneficialOwner).personDetails?.middleName,
                    lastName:
                        (step5 as BeneficialOwnerViewModel)?.lastName ??
                        (step5 as BeneficialOwner).personDetails?.lastName,
                    birthDate: formatDateToIsoString(
                        (step5 as BeneficialOwnerViewModel)?.birthDate ?? (step5 as BeneficialOwner).personDetails?.dob,
                    ),
                    addressType:
                        (step5 as BeneficialOwnerViewModel)?.addressType ??
                        (step5 as BeneficialOwner).personDetails?.address?.addressType,
                    streetAddress:
                        (step5 as BeneficialOwnerViewModel)?.streetAddress ??
                        (step5 as BeneficialOwner).personDetails?.address?.address1,
                    streetAddress2:
                        (step5 as BeneficialOwnerViewModel)?.streetAddress2 ??
                        (step5 as BeneficialOwner).personDetails?.address?.address2,
                    city:
                        (step5 as BeneficialOwnerViewModel)?.city ??
                        (step5 as BeneficialOwner).personDetails?.address?.city,
                    state:
                        (step5 as BeneficialOwnerViewModel)?.state ??
                        (step5 as BeneficialOwner).personDetails?.address?.state,
                    zip:
                        (step5 as BeneficialOwnerViewModel)?.zip ??
                        (step5 as BeneficialOwner).personDetails?.address?.zip,
                    country:
                        (step5 as BeneficialOwnerViewModel)?.country ??
                        (step5 as BeneficialOwner).personDetails?.address?.country,
                    beneficialOwnerId: step5.beneficialOwnerId,
                })),
            )) as BeneficialOwnerViewModel[];
        };

        const getStep5AddressComponent = (index: number) => {
            return {
                address_1: form.value.step5?.[index]?.streetAddress,
                address_2: form.value.step5?.[index]?.streetAddress2,
                address_type: form.value.step5?.[index]?.addressType,
                city: form.value.step5?.[index]?.city,
                state: form.value.step5?.[index]?.state,
                zip: form.value.step5?.[index]?.zip,
                country: form.value.step5?.[index]?.country,
            } as AddressComponent;
        };

        const getStep5InformationComponent = (index: number) => {
            return {
                firstName: form.value.step5?.[index]?.firstName,
                namePrefix: form.value.step5?.[index]?.middleName,
                lastName: form.value.step5?.[index]?.lastName,
                dob: inputDateFormat(form.value.step5?.[index]?.birthDate),
            } as InformationComponent;
        };

        const fetchStep5 = async () => {
            try {
                const beneficialOwnerList = await formService.getBeneficialOwners(formId.value);
                const beneficialOwners = beneficialOwnerList.beneficialOwners?.map((owner) => ({
                    isMinor: !!owner.isMinorOrChild,
                    firstName: owner.personDetails?.firstName || "",
                    middleName: owner.personDetails?.middleName || "",
                    lastName: owner.personDetails?.lastName || "",
                    birthDate: owner.personDetails?.dob || "",
                    addressType: owner.personDetails?.address?.addressType || "",
                    streetAddress: owner.personDetails?.address?.address1 || "",
                    streetAddress2: owner.personDetails?.address?.address2 || "",
                    city: owner.personDetails?.address?.city || "",
                    state: owner.personDetails?.address?.state || "",
                    zip: owner.personDetails?.address?.zip || "",
                    country: owner.personDetails?.address?.country || "",
                    beneficialOwnerId: owner.beneficialOwnerId || "",
                })) as BeneficialOwnerViewModel[];

                saveStep5(beneficialOwners);
            } catch (error) {
                // used to be empty
            }
        };

        const saveStep6Document = (data: BeneficialOwnerDocumentViewModel) => {
            const step6Buf = form.value.step6!.documents!.filter((f) => f.beneficialOwnerId !== data.beneficialOwnerId);
            step6Buf.push(data);
            form.value.step6!.documents! = step6Buf;
        };

        const getStep6Document = async (beneficialOwnerId: string) => {
            const step6CurrentBo = form.value.step6?.documents?.find((f) => f.beneficialOwnerId === beneficialOwnerId);

            return {
                formId: formId.value,
                deviceId: await useAuthStore().getDeviceId(),
                beneficialOwnerId: step6CurrentBo?.beneficialOwnerId,
                beneficialOwnerDocumentType: step6CurrentBo?.beneficialOwnerDocumentType,
                identificationNumber: step6CurrentBo?.identificationNumber,
                stateOfIssue:
                    step6CurrentBo?.beneficialOwnerDocumentType === IdentificationTypes.UsDriverLicense ||
                    step6CurrentBo?.beneficialOwnerDocumentType === IdentificationTypes.StateId
                        ? step6CurrentBo?.stateOfIssue
                        : null,
                expirationDate: formatDateToIsoString(step6CurrentBo?.expirationDate),
                countryOfIssue:
                    step6CurrentBo?.beneficialOwnerDocumentType === IdentificationTypes.ForeignPassport
                        ? step6CurrentBo?.countryOfIssue
                        : StateCountries.US,
            } as BeneficialOwnerDocumentViewModel;
        };

        const saveStep6Image = (data: ApplicantDocumentImage) => {
            const step6ImageBuf = form.value.step6!.images!.filter(
                (f) => f.beneficialOwnerId !== data.beneficialOwnerId,
            );
            step6ImageBuf.push(data);
            form.value.step6!.images! = step6ImageBuf;
        };

        const getStep6Image = async (beneficialOwnerId: string) => {
            const step6ImageCurrentBo = form.value.step6?.images?.find(
                (f) => f.beneficialOwnerId === beneficialOwnerId,
            );

            return {
                formId: formId.value,
                deviceId: await useAuthStore().getDeviceId(),
                userId: useUserStore().userUid,
                beneficialOwnerId: step6ImageCurrentBo?.beneficialOwnerId,
                document: step6ImageCurrentBo?.document,
            } as ApplicantDocumentImage;
        };

        const fetchStep6 = async () => {
            const ownerIds = form.value.step5?.map((owner) => owner.beneficialOwnerId) || [];
            const tasks: Promise<IdDocument>[] = [];

            try {
                for (const ownerId of ownerIds) {
                    if (!ownerId) {
                        continue;
                    }

                    tasks.push(formService.getBeneficialOwnerDocument(formId.value, ownerId));
                }

                const results = await Promise.all(tasks);
                for (const result of results) {
                    const ownerDocument = {
                        beneficialOwnerId: result.beneficialOwnerId,
                        beneficialOwnerDocumentType: result.type,
                        identificationNumber: result.number,
                        stateOfIssue: result.stateIssued,
                        expirationDate: result.expirationDate,
                        countryOfIssue: result.countryIssued,
                    } as BeneficialOwnerDocumentViewModel;

                    const ownerFile = {
                        beneficialOwnerId: result.beneficialOwnerId,
                        filename: result.filename,
                    } as ApplicantDocumentImage;

                    saveStep6Document(ownerDocument);
                    saveStep6Image(ownerFile);
                }
            } catch (error) {
                // used to be empty
            }
        };

        const removeStep6DetachedIds = () => {
            const ownerIds =
                form.value.step5?.filter((f) => !!f.beneficialOwnerId).map((owner) => owner.beneficialOwnerId!) || [];

            form.value.step6!.documents = form.value.step6!.documents!.filter((f) =>
                ownerIds.some((id) => id === f.beneficialOwnerId),
            );

            form.value.step6!.images = form.value.step6!.images!.filter((f) =>
                ownerIds.some((id) => id === f.beneficialOwnerId),
            );

            failedBoIds.value = failedBoIds.value.filter((f) => ownerIds.some((id) => id === f));
            failedBoImageIds.value = failedBoImageIds.value.filter((f) => ownerIds.some((id) => id === f));
        };

        const saveStep8 = (data: PaymentDetailsViewModel) => {
            form.value.step8 = data;
        };

        const getDeviceInfo = () => {
            const userAgent = navigator.userAgent;
            let deviceTypeVal = "Unknown";

            if (/Mobi|Android/i.test(userAgent)) {
                deviceTypeVal = "Mobile";
            } else if (/iPad|Tablet/i.test(userAgent)) {
                deviceTypeVal = "Tablet";
            } else {
                deviceTypeVal = "Desktop";
            }

            deviceType.value = deviceTypeVal;
        };

        const setLastModifiedForm = (formVal: Form | undefined) => {
            lastModifiedForm.value = formVal;
        };

        const isLastModifiedFormLocked = () => {
            return !!lastModifiedForm.value?.isLocked || !lastModifiedForm.value?.isEditable;
        };

        const getLastModifiedStep = async () => {
            if (!formId.value) {
                return;
            }

            const response = await formService.getFormMeta(formId.value);
            lastModifiedStep.value = response.metadata?.step;

            if (lastModifiedStep.value === 8 && formIsPaid(lastModifiedForm.value)) {
                lastModifiedStep.value = 10;
                return;
            }

            const subStepRange =
                !!response.metadata?.subStep && response.metadata.subStep >= 1 && response.metadata.subStep <= 5
                    ? response.metadata.subStep
                    : null;

            if (lastModifiedStep.value === 3) {
                setSubStep(!!subStepRange && subStepRange === 1 ? subStepRange : null);
            } else if (lastModifiedStep.value === 4) {
                setSubStep(subStepRange);
            } else if (lastModifiedStep.value === 6) {
                setSubStep(subStepRange);
            } else {
                setSubStep(null);
            }
        };

        const setLastModifiedStep = (stepVal: number) => {
            lastModifiedStep.value = stepVal;
        };

        const fetchAllBeforeStep2 = () => {
            fetchStep1();
        };

        const fetchAllBeforeStep3 = () => {
            fetchStep1();
            fetchStep2();

            if (subStep.value === 1) {
                fetchStep3();
            }
        };

        const fetchAllBeforeStep4 = async () => {
            fetchStep1();
            fetchStep2();
            fetchStep3();

            if (subStep.value) {
                const subStepData = [fetchStep4Applicant()];
                if (subStep.value > 3) {
                    subStepData.push(fetchStep4DocumentAndFile());
                }

                if (subStep.value > 4) {
                    subStepData.push(fetchStep4Filer());
                }

                await Promise.all(subStepData);
            }
        };

        const fetchAllBeforeStep5 = async () => {
            fetchStep1();
            fetchStep2();
            fetchStep3();
            await Promise.all([fetchStep4Applicant(), fetchStep4DocumentAndFile(), fetchStep4Filer()]);
        };

        const fetchAllBeforeStep6 = async () => {
            fetchStep1();
            fetchStep2();
            fetchStep3();
            await Promise.all([fetchStep4Applicant(), fetchStep4DocumentAndFile(), fetchStep4Filer(), fetchStep5()]);
        };

        const fetchAll = async () => {
            fetchStep1();
            fetchStep2();
            fetchStep3();
            await Promise.all([fetchStep4Applicant(), fetchStep4DocumentAndFile(), fetchStep4Filer(), fetchStep5()]);
            await fetchStep6();
        };

        const downloadFormData = async () => {
            if (!lastModifiedStep.value) {
                return;
            }

            switch (lastModifiedStep.value) {
                case 2: {
                    fetchAllBeforeStep2();
                    break;
                }
                case 3: {
                    fetchAllBeforeStep3();
                    break;
                }
                case 4: {
                    await fetchAllBeforeStep4();
                    break;
                }
                case 5: {
                    await fetchAllBeforeStep5();
                    break;
                }
                case 6: {
                    await fetchAllBeforeStep6();
                    break;
                }
                case 7: {
                    await fetchAll();
                    break;
                }
                case 8: {
                    await fetchAll();
                    break;
                }
                default: {
                    // if lastModifiedStep is present this data should be available
                    fetchAllBeforeStep3();
                    break;
                }
            }
        };

        const reset = () => {
            form.value = {
                step4: {},
                step6: {
                    documents: [],
                    images: [],
                },
            };

            subStep.value = null;
            deviceType.value = "Unknown";
            formId.value = "";
            setLastModifiedForm(undefined);
            lastModifiedStep.value = undefined;
            failedBoIds.value = [];
            failedBoImageIds.value = [];
        };

        const trackForm = (step: number) => {
            const ein_ssn_itin = form.value.step3?.domesticTaxIdType === "TAX_ID_TYPE_EIN" ? "ein" : "ssn";

            const isBeneficialOwnerOrApplicantDictionary = {
                [ApplicantTypes.Both]: "owner_and_applicant",
                [ApplicantTypes.BeneficialOnly]: "owner",
                [ApplicantTypes.ApplicantOnly]: "applicant",
                [ApplicantTypes.Neither]: "neither",
            };

            const ownerFromStore = form.value.step4?.applicant?.applicantType;
            const is_beneficial_owner_or_applicant = ownerFromStore
                ? isBeneficialOwnerOrApplicantDictionary[ownerFromStore]
                : null;
            const is_beneficial_owner = booleanToEvent(form.value.step4?.applicant?.isApplicantBeneficialOwner);
            const step_4_data = form.value.step1?.createdBeforeDate
                ? { is_beneficial_owner }
                : { is_beneficial_owner_or_applicant };

            useGtm()?.push({
                event: BoirFormEvent,
                step,
                step_1_data: {
                    created_before_2024: booleanToEvent(form.value.step1?.createdBeforeDate),
                    foreign_pooled_investment_vehicle: booleanToEvent(
                        form.value.step1?.isForeignPooledInvestmentVehicle,
                    ),
                },
                ...(step > 1
                    ? {
                          step_2_data: {
                              based_in_us: booleanToEvent(form.value.step2?.usCompany),
                          },
                      }
                    : {}),
                ...(step > 2
                    ? {
                          step_3_data: {
                              ein_ssn_itin,
                          },
                      }
                    : {}),
                ...(step > 3 ? { step_4_data } : {}),
            });
        };

        return {
            form,
            subStep,
            deviceType,
            formId,
            lastModifiedForm,
            lastModifiedStep,
            failedBoIds,
            failedBoImageIds,
            setSubStep,
            saveFormId,
            addFailedBoIds,
            removeFailedBoIds,
            addFailedBoImageIds,
            removeFailedBoImageIds,
            formIsPaid,
            formIsRefunded,
            saveStep1,
            getStep1,
            fetchStep1,
            saveStep2,
            getStep2,
            fetchStep2,
            saveStep3,
            getStep3StreetAddress,
            getStep3StreetAddress2,
            getStep3City,
            getStep3State,
            getStep3Zip,
            getStep3AddressComponent,
            getStep3AddressComponentReview,
            getStep3,
            fetchStep3,
            saveStep4Applicant,
            getStep4Applicant,
            getStep4ApplicantAddressComponent,
            getStep4ApplicantInformationComponent,
            fetchStep4Applicant,
            saveStep4Document,
            getStep4Document,
            saveStep4Image,
            getStep4Image,
            fetchStep4DocumentAndFile,
            saveStep4Filer,
            getStep4Filer,
            getStep4FilerAddressComponent,
            getStep4FilerInformationComponent,
            fetchStep4Filer,
            saveStep5,
            getStep5,
            getStep5AddressComponent,
            getStep5InformationComponent,
            fetchStep5,
            saveStep6Document,
            getStep6Document,
            saveStep6Image,
            getStep6Image,
            fetchStep6,
            removeStep6DetachedIds,
            saveStep8,
            getDeviceInfo,
            setLastModifiedForm,
            isLastModifiedFormLocked,
            getLastModifiedStep,
            setLastModifiedStep,
            fetchAllBeforeStep2,
            fetchAllBeforeStep3,
            fetchAllBeforeStep4,
            fetchAllBeforeStep5,
            fetchAllBeforeStep6,
            fetchAll,
            downloadFormData,
            reset,
            trackForm,
        };
    },
    { persist: true },
);
