export const IdTypes = {
    EIN: "TAX_ID_TYPE_EIN",
    SSN: "TAX_ID_TYPE_SSN_ITIN",
    ForeignEIN: "FOREIGN_TAX_ID_TYPE_US_TAX_ID_EIN",
    Foreign: "FOREIGN_TAX_ID_TYPE_FOREIGN_TAX_ID",
};

export const deviceTypes = {
    //
};
