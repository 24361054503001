import { get, post } from "./api-client";
import { useAuthStore } from "@/stores/authStore";
import { PaymentProfile } from "@/models/payment-profile";
import { PaymentContextRequest } from "@/models/payment-context-request";
import { PaymentMerchantConfig } from "@/models/payment-merchant-config";
import { PaymentProfileRequest } from "@/models/payment-profile-request";
import { ProfilePaymentRequest } from "@/models/profile-payment-request";
import { PaymentResult } from "@/models/payment-result";

const basePath = import.meta.env.VITE_EXT_FORM_API;
function axiosRequestConfig() {
    return {
        headers: {
            Authorization: `Bearer ${useAuthStore().authToken}`,
        },
    };
}

const service = {
    paymentContext: (data: PaymentContextRequest): Promise<PaymentMerchantConfig> =>
        post(basePath, "/payment/context?force_merchant=stripe", data, axiosRequestConfig()),
    paymentProfiles: (): Promise<PaymentProfile[]> => get(basePath, "/payment/profiles", axiosRequestConfig()),
    paymentProfile: (data: PaymentProfileRequest): Promise<PaymentProfile> =>
        post(basePath, "/payment/profile", data, axiosRequestConfig()),
    paymentCharge: (profileId: string, data: ProfilePaymentRequest): Promise<PaymentResult> =>
        post(basePath, `/payment/charge/${profileId}`, data, axiosRequestConfig()),
};

export default service;
