import router from "@/router";
import { externalDashboardPath } from "@/router/routes";

/**
 * @deprecated This function is deprecated and will be removed in future versions
 * in favor of the session timeout page.
 */
export function dashboardRedirect() {
    const externalDashboardUrl = import.meta.env.VITE_DASHBOARD_URL;
    const ROUTES = {
        EXTERNAL_DASHBOARD: "external-dashboard",
    };

    if (externalDashboardUrl) {
        window.location.href = externalDashboardUrl;
    } else {
        router.push({ name: ROUTES.EXTERNAL_DASHBOARD });
    }
}

export const validateErrorPage = () => {
    const currentPath = window.location.pathname;

    return currentPath === externalDashboardPath;
};
