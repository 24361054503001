import { defineStore } from "pinia";
import { ref } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useStore } from "@/stores/formStore";
import { dashboardRedirect, validateErrorPage } from "@/util/dashboardRedirect";
import { resetFormData } from "./reset-stores";
import { validateLandingPage } from "@/util/helper";
import { useSession } from "@/composables/useSession";
import formService from "@/services/form-service";

export const useAppStore = defineStore("application", () => {
    const isTokenFetching = ref(false);
    const isFormFetching = ref(false);

    const refreshInitData = async () => {
        const authStore = useAuthStore();
        const { refreshToken } = useSession();

        // force refresh for landing page
        if (!validateErrorPage() && (!authStore.authToken || validateLandingPage())) {
            try {
                isTokenFetching.value = true;
                await refreshToken(true); // Force refresh to handle initial token acquisition

                // Only redirect if we still don't have a token after the refresh attempt
                if (!authStore.hasAuthToken) {
                    console.error("No token found / refresh failed");
                    dashboardRedirect();
                }
            } finally {
                isTokenFetching.value = false;
            }
        }
    };

    const getForm = async (formIdVal: string) => {
        try {
            return await formService.getForm(formIdVal);
        } catch (error) {
            // used to be empty
        }

        return undefined;
    };

    const editForm = async (formIdVal: string) => {
        try {
            const requestData = {
                formId: formIdVal,
                deviceId: await useAuthStore().getDeviceId(),
            };

            return await formService.editForm(requestData);
        } catch (error) {
            // used to be empty
        }

        return undefined;
    };

    const stopFormFetchingAndRedirect = () => {
        isFormFetching.value = false;
        dashboardRedirect();
    };

    const manageForm = async () => {
        isFormFetching.value = true;
        // force reset form data
        resetFormData();

        const formStore = useStore();
        const pathFormId = new URLSearchParams(window.location.search).get("formId");

        if (!pathFormId) {
            isFormFetching.value = false;
            return;
        }

        const editFormResponse = await editForm(pathFormId);
        if (!editFormResponse || !editFormResponse.formId || !editFormResponse.editAllowed) {
            stopFormFetchingAndRedirect();
            return;
        }

        // IMPORTANT! form id should be received from edit form response
        const formResponse = await getForm(editFormResponse.formId);
        formStore.setLastModifiedForm(formResponse);

        if (
            !formStore.lastModifiedForm ||
            formStore.isLastModifiedFormLocked() ||
            formStore.formIsRefunded(formStore.lastModifiedForm)
        ) {
            stopFormFetchingAndRedirect();
            return;
        }

        formStore.saveFormId(formStore.lastModifiedForm?.formId || "");
        await formStore.getLastModifiedStep();
        await formStore.downloadFormData();
        isFormFetching.value = false;
    };

    return {
        isTokenFetching,
        isFormFetching,
        refreshInitData,
        manageForm,
    };
});
