import type { UserProfile } from "@/models/user-profile";

import { usePostHog } from "./usePosthog";

export function useUserAnalytics() {
    const posthogInstance = usePostHog();
    const identifyUser = (user: UserProfile) => {
        if (!posthogInstance || !user) {
            return;
        }

        if (posthogInstance.get_distinct_id() !== user.id) {
            posthogInstance.identify(user.id, {
                id: user.id,
                email: user.email,
                name: `${user.first_name} ${user.last_name}`,
            });
        }
    };

    return {
        identifyUser,
    };
}
