export const ProductTypeEvent = "add_to_cart";
export const ProductTypeLabel = "Add to cart";
export const Step8Category = "boir_step_8";
export const ClickAction = "click";
export const EINValue = "ein";
export const SSNorITINValue = "ssn_or_itin";
export const ForeignEINValue = "foreign_ein";
export const ForeignValue = "foreign";
export const ApplicantAndOwnerValue = "owner_and_applicant";
export const BeneficialOnlyValue = "owner";
export const ApplicantOnly = "applicant";
export const NeitherApplicantOrOwner = "neither";

export const ProductPr85OtpValue = "pr85_otp";
export const ProductPr84SubValue = "pr84_sub";

export const BoirFormEvent = "boir_form";

export const booleanToEvent = (value?: boolean): "yes" | "no" => {
    return value ? "yes" : "no";
};
