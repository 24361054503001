import { defaultPath } from "@/router/routes";

export const additionalOwners = [
    { id: 1, title: "Second", prefix: "2nd" },
    { id: 2, title: "Third", prefix: "3rd" },
    { id: 3, title: "Fourth", prefix: "4th" },
    { id: 4, title: "Fifth", prefix: "5th" },
];

export const parseFullName = (fullName: string): { firstName: string; lastName: string } => {
    fullName = fullName.trim();
    const parts = fullName.split(/\s+/);

    if (parts.length === 0) {
        return { firstName: "", lastName: "" };
    } else if (parts.length === 1) {
        return { firstName: parts[0], lastName: "" };
    } else {
        const firstName = parts[0];
        const lastName = parts.slice(1).join(" ");

        return { firstName, lastName };
    }
};

export const unknownIfEmpty = (val: string) => (val && val.length > 2 ? val : "unknown");

export const getEngineSource = (): string => window.location.hostname.replace("www.", "");

export const moneyFormatUSD = (price: number, wholeDollars: boolean = false) => {
    return (price / 100.0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        ...(wholeDollars &&
            Number.isInteger(price / 100.0) && {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }),
    });
};

export const formatDateToIsoString = (date: string | undefined | null) => {
    if (!date) {
        return null;
    }

    const dateObject = new Date(date);

    if (Object.prototype.toString.call(dateObject) === "[object Date]" && !isNaN(dateObject.getTime())) {
        return dateObject.toISOString();
    }

    return null;
};

export const inputDateFormat = (isoDate: string | undefined | null) => {
    if (!isoDate) {
        return "";
    }

    const splitDate = isoDate.split("T");
    if (!splitDate[0]) {
        return "";
    }

    return splitDate[0];
};

export const validateLandingPage = () => {
    const currentPath = window.location.pathname;

    return currentPath === defaultPath || currentPath.startsWith(`${defaultPath}?formId=`);
};
