export const FormFileValidationFailure = "BR1002";
export const EditFormIsLocked = "BR1100";

export const GetFormError = "BR2001";
export const GetFormMetaError = "BR2002";
export const GetCompanyApplicantError = "BR2003";
export const GetCompanyApplicantDocumentError = "BR2004";
export const GetFilerError = "BR2005";
export const GetBeneficialOwnersError = "BR2006";
export const GetBeneficialOwnerDocumentError = "BR2007";
