import { inject, InjectionKey } from "vue";
import posthog, { PostHog } from "posthog-js";
import { CookieName, readCookie } from "@/util/cookies";

export const PostHogSymbol: InjectionKey<PostHog> = Symbol("PostHog");

export function initPostHog(): PostHog | undefined {
    const currentUrl = new URL(window.location.href);

    // Check URL first, then fallback to cookie
    const sessionID = currentUrl.searchParams.get("session_id") || readCookie(CookieName.PH_SESSION_ID);

    const bootstrapConfig = sessionID ? { bootstrap: { sessionID } } : {};

    const posthogInstance = posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
        api_host: import.meta.env.VITE_POSTHOG_URL,
        ui_host: "https://us.posthog.com",
        person_profiles: "identified_only",
        ...bootstrapConfig,
        loaded: (phInstance: PostHog) => {
            const ingestId = readCookie(CookieName.INGEST_ID);
            if (ingestId) {
                phInstance.identify(ingestId);
            }
        },
    });

    return posthogInstance;
}

export function usePostHog(): PostHog | undefined {
    const posthogInstance = inject(PostHogSymbol);
    if (!posthogInstance) {
        console.error("PostHog has not been provided");
        return undefined;
    }

    return posthogInstance;
}
