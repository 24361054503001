export const sessionStorageNames = Object.freeze({
    businessName: "business_name",
});

export const getSessionStorageFromQs = () => {
    const businessName = new URL(window.location.href).searchParams.get(sessionStorageNames.businessName);

    if (businessName) {
        sessionStorage.setItem(sessionStorageNames.businessName, businessName);
    }
};
