import { post } from "./api-client";
import { RefreshTokenResponse } from "@/models/refresh-token-response";

const basePath = import.meta.env.VITE_EXT_FORM_API;

const service = {
    refreshToken: (): Promise<RefreshTokenResponse> =>
        post(basePath, "/oauth/refresh", null, { withCredentials: true }),
};

export default service;
