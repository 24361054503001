/**
 * Cookie expiration times in milliseconds
 */
export const CookieExpiration = {
    THIRTY_MINUTES: 30 * 60 * 1000,
    FIVE_DAYS: 5 * 24 * 60 * 60 * 1000,
    THREE_MONTHS: 90 * 24 * 60 * 60 * 1000,
} as const;

/**
 * Cookie names used in the application
 */
export const CookieName = {
    INGEST_ID: "ingest_id",
    REDTRACK_CLICK_ID: "rtkclkid",
    REDTRACK_COMPANY_ID: "rtkcmpid",
    PH_SESSION_ID: "phSessionId",
} as const;

export type CookieNameType = (typeof CookieName)[keyof typeof CookieName];

/**
 * Interface for cookie options
 */
export interface CookieOptions {
    domain?: string;
    sameSite?: "Strict" | "Lax" | "None";
    secure?: boolean;
    path?: string;
}

/**
 * Interface for tracking cookie configuration
 */
interface TrackingCookie {
    name: CookieNameType;
    expires: number;
}

/**
 * Default cookie options
 */
const DEFAULT_COOKIE_OPTIONS: Required<CookieOptions> = {
    domain: "",
    sameSite: "Lax",
    secure: true,
    path: "/",
};

/**
 * Reads a cookie value by its name
 * @param name - The name of the cookie to read
 * @returns The cookie value or null if not found
 */
export const readCookie = (name: string): string | null => {
    try {
        const cookies = document.cookie.split(";");
        const cookie = cookies.find((c) => c.trim().startsWith(`${name}=`));

        if (!cookie) {
            return null;
        }

        return decodeURIComponent(
            cookie
                .trim()
                .substring(name.length + 1)
                .replace(/\+/g, " "),
        );
    } catch (error) {
        console.error(`Error reading cookie ${name}:`, error);
        return null;
    }
};

/**
 * Stores a cookie with the specified parameters
 * @param name - The name of the cookie
 * @param value - The value to store
 * @param expirationMs - Expiration time in milliseconds
 * @param options - Additional cookie options
 */
export const storeCookie = (name: string, value: string, expirationMs: number, options: CookieOptions = {}): void => {
    try {
        const mergedOptions: Required<CookieOptions> = { ...DEFAULT_COOKIE_OPTIONS, ...options };
        const date = new Date();
        date.setTime(date.getTime() + expirationMs);

        const cookieParts = [
            `${name}=${encodeURIComponent(value)}`,
            `expires=${date.toUTCString()}`,
            `path=${mergedOptions.path}`,
            `SameSite=${mergedOptions.sameSite}`,
        ];

        if (mergedOptions.domain) {
            cookieParts.push(`domain=${mergedOptions.domain}`);
        }

        if (mergedOptions.secure) {
            cookieParts.push("Secure");
        }

        document.cookie = cookieParts.join("; ");
    } catch (error) {
        console.error(`Error storing cookie ${name}:`, error);
    }
};

/**
 * Configuration for tracking cookies
 */
const TRACKING_COOKIES: TrackingCookie[] = [
    {
        name: CookieName.REDTRACK_CLICK_ID,
        expires: CookieExpiration.THREE_MONTHS,
    },
    {
        name: CookieName.REDTRACK_COMPANY_ID,
        expires: CookieExpiration.THREE_MONTHS,
    },
    {
        name: CookieName.INGEST_ID,
        expires: CookieExpiration.FIVE_DAYS,
    },
    {
        name: CookieName.PH_SESSION_ID,
        expires: CookieExpiration.THIRTY_MINUTES,
    },
];

/**
 * Stores tracking cookies from URL query parameters
 * @param url - Optional URL to extract parameters from, defaults to window.location
 */
export const storeTrackingCookies = (url: URL = new URL(window.location.href)): void => {
    try {
        TRACKING_COOKIES.forEach(({ name, expires }) => {
            const value = url.searchParams.get(name);
            if (value) {
                storeCookie(name, value, expires);
            }
        });
    } catch (error) {
        console.error("Error storing tracking cookies:", error);
    }
};
