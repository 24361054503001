import { App } from "vue";
import { type AxiosStatic } from "axios";
import { axiosInjectionKey } from "@/constants/injection-keys";
import { useAxios } from "./useAxios";

export default {
    install(app: App, axios: AxiosStatic) {
        axios.defaults.baseURL = "/api/";
        app.config.globalProperties.axios = axios;
        app.provide(axiosInjectionKey, axios);
    },
};

export { useAxios };
