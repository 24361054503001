import axios from "axios";

import { ApiError } from "@/util/api-error";

export async function get<TResponseData = any>(basePath: string, url: string, config?: any) {
    try {
        return (await axios.get<TResponseData>(`${basePath}${url}`, config)).data;
    } catch (error) {
        throw new ApiError(error);
    }
}

export async function post<TData = any, TResponseData = any>(basePath: string, url: string, data: TData, config?: any) {
    try {
        return (await axios.post<TResponseData>(`${basePath}${url}`, data, config)).data;
    } catch (error) {
        throw new ApiError(error);
    }
}

export async function put<TData = any, TResponseData = any>(basePath: string, url: string, data: TData, config?: any) {
    try {
        return (await axios.put<TResponseData>(`${basePath}${url}`, data, config)).data;
    } catch (error) {
        throw new ApiError(error);
    }
}

export async function del<TResponseData = any>(basePath: string, url: string, config?: any) {
    try {
        return (await axios.delete<TResponseData>(`${basePath}${url}`, config)).data;
    } catch (error) {
        throw new ApiError(error);
    }
}
