import type { Agent } from "@fingerprintjs/fingerprintjs";
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useUserStore } from "./userStore";
import { resetUserData } from "./reset-stores";
import { UserProfile } from "@/models/user-profile";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { getSHA1 } from "@/util/crypto";

type TokenReadyCallback = () => void;

export const useAuthStore = defineStore("auth", () => {
    const deviceId = ref("");
    const authToken = ref("");
    const hasAuthToken = computed(() => !!authToken.value);
    const tokenReadyCallbacks = new Set<TokenReadyCallback>();

    const setAuthToken = (token: string) => {
        authToken.value = token;
        // Notify all subscribers when token is set
        tokenReadyCallbacks.forEach((callback) => callback());
        tokenReadyCallbacks.clear(); // Clear callbacks after notifying
    };

    const clearAuth = () => {
        authToken.value = "";
        resetUserData();
        tokenReadyCallbacks.clear(); // Clear callbacks on auth clear
    };

    const updateUserData = (user?: UserProfile) => {
        const userStore = useUserStore();
        const userId = user?.id ?? "";

        if (user) {
            userStore.setUserData(userId, user.first_name, user.last_name, user.email);
        }
    };

    const updateDeviceId = async () => {
        await FingerprintJS.load().then(async (agent: Agent) => {
            const { visitorId } = await agent.get();
            const fpSHA1 = await getSHA1(visitorId);
            setDeviceId(fpSHA1);
        });
    };

    const setDeviceId = (id: string) => {
        deviceId.value = id;
    };

    const getDeviceId = async () => {
        if (!deviceId.value) {
            await updateDeviceId();
        }
        return deviceId.value;
    };

    // New method to subscribe to token ready event
    const onTokenReady = (callback: TokenReadyCallback) => {
        if (hasAuthToken.value) {
            // If token already exists, call callback immediately
            callback();
            return () => {}; // Return empty cleanup function
        }

        // Otherwise, add to subscribers
        tokenReadyCallbacks.add(callback);

        // Return cleanup function
        return () => {
            tokenReadyCallbacks.delete(callback);
        };
    };

    return {
        deviceId,
        authToken,
        hasAuthToken,
        setAuthToken,
        clearAuth,
        updateUserData,
        updateDeviceId,
        setDeviceId,
        getDeviceId,
        onTokenReady,
    };
});
