import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { PaymentProfile } from "@/models/payment-profile";
import paymentService from "@/services/payment-service";
import { PaymentConfigRequest } from "@/models/payment-config-request";
import { PaymentContextRequestType } from "@/models/payment-context-request-type";
import { PaymentMerchantConfig } from "@/models/payment-merchant-config";
import { useAuthStore } from "@/stores/authStore";

export const usePaymentStore = defineStore("payment", () => {
    const paymentConfig = ref({} as PaymentMerchantConfig);
    const paymentProfiles = ref(undefined as PaymentProfile[] | undefined);

    const defaultPaymentProfile = computed((): PaymentProfile | undefined => {
        return paymentProfiles.value?.find((profile) => profile.active && profile.is_default);
    });

    const setPaymentConfig = (config: PaymentMerchantConfig | undefined) => {
        paymentConfig.value = config ?? {};
    };

    const setPaymentProfiles = (profiles: PaymentProfile[]) => {
        paymentProfiles.value = profiles;
    };

    const getPaymentConfig = async ({ contextType = PaymentContextRequestType.Basic }: PaymentConfigRequest) => {
        const hostname = window.location.host;
        const deviceId = await useAuthStore().getDeviceId();

        const config = await paymentService.paymentContext({
            hostname,
            device_id: deviceId,
            context_type: contextType,
        });

        setPaymentConfig(config);
    };

    const getPaymentProfiles = async () => {
        const profiles = await paymentService.paymentProfiles();
        setPaymentProfiles(profiles);
    };

    const appendPaymentProfile = (profile: PaymentProfile) => {
        const profiles = paymentProfiles.value ?? [];
        const profileIndex = profiles.findIndex((p) => p.id === profile.id);

        if (profileIndex !== -1) {
            profiles[profileIndex] = profile;
        } else {
            paymentProfiles.value = [...profiles, profile];
        }
    };

    const reset = () => {
        paymentConfig.value = {};
        paymentProfiles.value = undefined;
    };

    return {
        paymentConfig,
        paymentProfiles,
        defaultPaymentProfile,
        setPaymentConfig,
        setPaymentProfiles,
        getPaymentConfig,
        getPaymentProfiles,
        appendPaymentProfile,
        reset,
    };
});
