export const MaxFileSize = 4000000;
export const AllowedFileTypes = "image/png,image/jpeg,application/pdf";
export const DefaultFormStep = 1;
export const FileNameMaxFileSize = 250;
export const BoirSinglePurchase = "pr85";
export const BoirSubscriptionPurchase = "pr84";
export const AuthTokenPollingFrequencyInMs = 500;
export const DobExceedYears = 120;
export const THREE_MONTHS_IN_MS = 1000 * 60 * 60 * 24 * 30 * 3;
export const FIVE_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 5;
export const Unspecified = "unset";
