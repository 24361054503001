import * as Sentry from "@sentry/vue";
import { SentryRequestContext, SentryResponseContext } from "./types";
import { useUserStore } from "@/stores/userStore";
import { useStore } from "@/stores/formStore";
import { Unspecified } from "@/constants/boir";

const cleanHeaders = (headers: any): Record<string, any> | undefined => {
    if (!headers) return undefined;

    // Convert AxiosHeaders or other header objects to plain object
    const plainHeaders = Object.entries(headers).reduce(
        (acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = value;
            }
            return acc;
        },
        {} as Record<string, any>,
    );

    return Object.keys(plainHeaders).length > 0 ? plainHeaders : undefined;
};

const cleanRequestInfo = (config: any) => {
    const info: Record<string, any> = {};

    if (config?.url) info.url = config.url;
    if (config?.method) info.method = config.method;

    const headers = cleanHeaders(config?.headers);
    if (headers) info.headers = headers;

    if (config?.data !== undefined && config.data !== null) info.data = config.data;

    return Object.keys(info).length > 0 ? info : undefined;
};

const cleanResponseInfo = (response: any) => {
    if (!response) return undefined;

    const info: Record<string, any> = {};

    if (response.status) info.status = response.status;
    if (response.statusText) info.statusText = response.statusText;

    const headers = cleanHeaders(response.headers);
    if (headers) info.headers = headers;

    if (response.data !== undefined && response.data !== null) info.data = response.data;

    return Object.keys(info).length > 0 ? info : undefined;
};

export const captureRequestError = (error: any) => {
    const requestInfo = cleanRequestInfo(error.config);

    const context: SentryRequestContext = {
        phase: "request",
        ...(requestInfo && { request: requestInfo }),
    };

    Sentry.captureException(error, {
        extra: context,
        tags: {
            boirUserId: useUserStore().userUid || Unspecified,
            boirFormId: useStore().formId || Unspecified,
        },
    });
};

export const captureResponseError = (
    error: any,
    additionalContext: Partial<Omit<SentryResponseContext, "phase" | "request">> = {},
) => {
    const requestInfo = cleanRequestInfo(error.config);
    const responseInfo = cleanResponseInfo(error.response);

    const context: SentryResponseContext = {
        phase: "response",
        type: additionalContext.type || "unknown",
        ...(requestInfo && { request: requestInfo }),
        ...additionalContext,
    };

    if (responseInfo) {
        context.response = responseInfo;
    }

    // Remove undefined fields from additional context
    Object.keys(context).forEach((key) => {
        if (context[key] === undefined || context[key] === null) {
            delete context[key];
        }
    });

    Sentry.captureException(error, {
        extra: context,
        tags: {
            boirUserId: useUserStore().userUid || Unspecified,
            boirFormId: useStore().formId || Unspecified,
        },
    });
};
