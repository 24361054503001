import "@/styles/app.scss";

import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persistedstate";
import * as Sentry from "@sentry/vue";

import App from "@/App.vue";
import router from "@/router";

import { createBootstrap } from "bootstrap-vue-next";

import axios from "axios";
import axiosPlugin from "@/plugins/axios";

import { createGtm } from "@gtm-support/vue-gtm";

import { vMaska } from "maska/vue";
import posthogPlugin from "@/plugins/posthog";

const app = createApp(App);

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
        release: import.meta.env.VITE_APP_VERSION,
        integrations: [
            Sentry.browserTracingIntegration({
                router,
            }),
        ],
        tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
        trackComponents: true,
    });
}

const pinia = createPinia();
pinia.use(piniaPersist);
app.use(pinia);

app.use(posthogPlugin);

app.use(router);

app.use(
    createGtm({
        id: import.meta.env.VITE_GTM_KEY,
        enabled: process.env.NODE_ENV !== "development",
        vueRouter: router,
    }),
);

app.use(createBootstrap());

app.use(axiosPlugin, axios);

app.directive("maska", vMaska);

app.mount("#app");
