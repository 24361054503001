import { ref, onMounted, onUnmounted } from "vue";
import { jwtDecode } from "jwt-decode";
import { useAuthStore } from "@/stores/authStore";
import authService from "@/services/auth-service";
import * as Sentry from "@sentry/vue";
import { useUserAnalytics } from "@/composables/useUserAnalytics";
import { useUserStore } from "@/stores/userStore";
import { useStore } from "@/stores/formStore";
import { Unspecified } from "@/constants/boir";

interface JwtPayload {
    exp: number;
}

const REFRESH_THRESHOLD = 120000; // 2 minutes in milliseconds

let refreshInterval: NodeJS.Timeout | null = null;
export function useSession() {
    const isInitialized = ref(false);
    const authStore = useAuthStore();
    const isRefreshing = ref(false);
    const isInitialRefresh = ref(true);
    const lastRefreshTime = ref(0);

    const { identifyUser } = useUserAnalytics();

    const getTokenExpirationTime = (token: string): number => {
        try {
            const decoded = jwtDecode<JwtPayload>(token);
            return decoded.exp * 1000; // Convert to milliseconds
        } catch (error) {
            Sentry.captureException(error, {
                extra: { context: "JWT decode failed" },
                tags: {
                    boirUserId: useUserStore().userUid || Unspecified,
                    boirFormId: useStore().formId || Unspecified,
                },
            });
            return 0;
        }
    };

    const shouldRefreshToken = (token: string): boolean => {
        if (!token) return true; // Always refresh if no token

        const expirationTime = getTokenExpirationTime(token);
        const now = Date.now();

        // If token is expired or expires soon, refresh it
        return expirationTime <= now || expirationTime - now < REFRESH_THRESHOLD;
    };

    const scheduleNextRefresh = (token: string) => {
        // Clear any existing refresh interval
        if (refreshInterval) {
            clearTimeout(refreshInterval);
            refreshInterval = null;
        }

        const expirationTime = getTokenExpirationTime(token);
        const now = Date.now();
        const timeUntilRefresh = Math.max(0, expirationTime - now - REFRESH_THRESHOLD);

        refreshInterval = setTimeout(async () => {
            await refreshToken();
        }, timeUntilRefresh);
    };

    /**
     * Refreshes or acquires the authentication token.
     *
     * This function handles both initial token acquisition and token refresh.
     * For initial acquisition, it will attempt to get a token using the refresh token cookie.
     * For refresh, it will check the token expiration and refresh if needed.
     *
     * @param {boolean} force - When true, bypasses the normal expiration check
     * and forces a token refresh
     * @returns {Promise<void>}
     */
    const refreshToken = async (force = false) => {
        // Prevent multiple refresh attempts within a short time window
        const now = Date.now();
        const lastDelta = now - lastRefreshTime.value;
        if (!force && lastDelta < 30 * 1000) {
            // Prevent refreshes within 30 seconds of each other
            return;
        }

        // Allow refresh attempts for initial token acquisition
        if (isRefreshing.value) {
            return;
        }

        // Check if refresh is needed
        if (!force && authStore.hasAuthToken && !shouldRefreshToken(authStore.authToken)) {
            scheduleNextRefresh(authStore.authToken);
            return;
        }

        try {
            isRefreshing.value = true;
            const response = await authService.refreshToken();
            if (response && response.jwt_token) {
                authStore.setAuthToken(response.jwt_token);

                // Only update user data on initial refresh
                // Also identify the user in posthog and restrict further updates
                if (isInitialRefresh.value && response.profile) {
                    authStore.updateUserData(response.profile);
                    identifyUser(response.profile);
                    isInitialRefresh.value = false;
                }
                lastRefreshTime.value = now;

                // Schedule next refresh based on new token's expiration
                scheduleNextRefresh(response.jwt_token);
            } else {
                authStore.clearAuth();
            }
        } catch (error) {
            Sentry.captureException(error, {
                extra: { context: "Token refresh failed in useSession" },
                tags: {
                    boirUserId: useUserStore().userUid || Unspecified,
                    boirFormId: useStore().formId || Unspecified,
                },
            });
            authStore.clearAuth();
            //TODO: Redirect to session timeout page. If token is dead - there is no sense in sending to the dashboards.
            throw error;
        } finally {
            isRefreshing.value = false;
        }
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
            refreshToken(true);
        }
    };

    const handleFocus = () => {
        refreshToken(true);
    };

    onMounted(() => {
        if (!isInitialized.value) {
            // Force an immediate refresh if we have an expired token
            if (authStore.hasAuthToken && shouldRefreshToken(authStore.authToken)) {
                refreshToken(true);
            } else {
                // No token, try to get one
                refreshToken();
            }
            isInitialized.value = true;
        }

        // This is to handle situtation when user leave a page for some reason
        // (switces tab or turns of the phone or browser) to try to recover token
        // as soon as possible. I'm not quite sure if this is correct implementation
        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("focus", handleFocus);
    });

    onUnmounted(() => {
        if (refreshInterval) {
            clearTimeout(refreshInterval);
            refreshInterval = null;
        }

        document.removeEventListener("visibilitychange", handleVisibilityChange);
        window.removeEventListener("focus", handleFocus);
    });

    return {
        refreshToken,
        isRefreshing,
        lastRefreshTime,
    };
}
