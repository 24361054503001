<template>
    <header class="app-header">
        <div class="container">
            <BNavbar toggleable="lg">
                <BNavbarBrand @click="redirect" exact class="d-flex">
                    <img src="@/assets/svg/logos/logo.svg" alt="logo" width="130px" height="51px" />
                </BNavbarBrand>
                <BNavbarToggle v-if="showLinks" target="nav-collapse" />
                <BCollapse id="nav-collapse" is-nav>
                    <BNavbarNav class="ms-auto"> </BNavbarNav>
                </BCollapse>
            </BNavbar>
        </div>
    </header>
</template>

<script setup lang="ts">
    import { computed } from "vue";
    import { dashboardRedirect } from "@/util/dashboardRedirect";
    import { useRouter } from "vue-router";
    import { defaultName } from "@/router/routes";

    const props = defineProps({
        authorized: {
            type: Boolean,
            default: false,
        },
    });

    const router = useRouter();

    const showLinks = computed(() => {
        if (!props.authorized) {
            return true;
        }

        const routeName =
            typeof router.currentRoute.value.name === "string" ? router.currentRoute.value.name : defaultName;

        return !["extension", "thank-you", "business-extension", "registration", "1click-login", "login"].includes(
            routeName,
        );
    });

    const redirect = () => {
        dashboardRedirect();
    };
</script>
